.card{
    /* width: 100%; */
    position: absolute;
    top: 30%;
    left: 30%;
    right: 30%;
    padding: 30px;
    
    /* margin-left: 20%;
    margin-right: 10%; */
    
}
.cards{
    /* width: 100%; */
    position: absolute;
    top: 10%;
    left: 32%;
    right: 32%;
    /* padding: 30px; */
    
    /* margin-left: 20%;
    margin-right: 10%; */
    
}

.container{
    /* margin: 0;
    padding: 0; */
    min-height: 100vh;
    background: linear-gradient(45deg, #2a83ad, #f0f2f0);
}
