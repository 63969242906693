.pyramid {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  width: 100vmin;
  aspect-ratio: 2 / 1;
  background-color: #fefefe;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5vmin;
}
.pyramid > #rank {
  background: pink;
}
.one {
  background-color: #FA9189;
}
.two {
  background-color: #FCAE7C;
}
.three {
  background-color: #FFE699
}
.four {
  background-color: #F9FFB5
}
.five {
  background-color: #B3F5BC;
}
.six {
  background-color: #D6F6FF;
}

.result-card {
  box-shadow: rgb(32 40 45 / 8%) 0px 1px 8px 0px !important;
  border: 1px solid #eae7e7;
  border-radius: 6px;
  padding: 15px 20px;
}

.votes-card {
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem !important;
  border: 1px solid #d0dbfb;
  border-radius: 6px;
  padding: 15px 20px;
}