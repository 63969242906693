.poll-panel {
    display: block;
    flex-wrap: wrap;
    border-radius: 8px !important;
    transform: scale(1);
    border: none;
    padding: 12px !important;
    /* box-shadow: rgb(0 0 0 / 10%) 0px 10px 45px -1px,
            rgb(0 0 0 / 4%) 0px 0px 0px -8px !important */
}

.poll-panel-active {
    /* transform: scale(1.01); */
    border: 1px solid #f1e1f4;
    transition: all 0.5s ease-in-out;
    /* box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%),
            0 50px 100px -20px rgb(50 50 93 / 25%),
            0 30px 60px -30px rgb(0 0 0 / 30%) !important; */
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 45px -1px,
            rgb(0 0 0 / 4%) 0px 0px 0px -8px !important
}

.side-bar {
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 45px -1px,
         rgb(0 0 0 / 4%) 0px 0px 0px -8px !important
}
