.vote-option .MuiFormControlLabel-label.Mui-disabled {
    color: inherit !important;
}

.vote-option .MuiRadio-colorPrimary.Mui-checked {
    color: #2a83ad !important;
}

/* .vote {
    border: 1px solid rgb(131, 123, 123);
} */

.round {
    border: 2px solid rgb(19, 17, 17);
    border-radius: 50%;
    padding: 8px 16px;
    width: fit-content;
    text-align: center;
    font-weight: 600;
    /* padding-left: 10px;
    padding-right: 10px; */

}

.paragraph {
    /* text-align: center;
    max-width: 220px; */
    word-wrap: break-word;
    word-break: break-all;
    /* text-align-last: left;
    border: 1px solid red; */
}