 .organizerPaper {
     padding: 0.5rem;
     border: 1px solid #5585dd;
     box-shadow: #a6adab17 0px 13px 27px -5px, #2369af 0px 8px 16px -8px !important;
     margin: 0rem 1rem 1rem 0rem;
     width: 40rem;
 }

 @media (min-width: 780px) {
     .organizerPaper {
         padding: 0.5rem;
         border: 1px solid #eae7e7;
         box-shadow: #a6adab17 0px 13px 27px -5px, #ced3d8 0px 8px 16px -8px !important;
         margin: 0rem 0rem 0rem 10rem;
         width: 23rem
     }
 }

 /* .waveContainer {
     position: absolute;
     justify-content: center;
     align-items: center;
 } */

 .waveContainer {
     /* height: 90vh; */
     overflow: hidden;
     position: relative;
 }

 .hello {
     top: 20%;
     left: 44%;
     position: absolute
 }

 .viewBox {
     height: 100%;
     width: 100%;
 }

 .path {
     stroke: none;
     fill: rgb(7, 115, 115);
 }