.popover__title {
    font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
    padding: 15px 0;
}

.popover__wrapper {
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.popover__content {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 1000;
    right: 25px;
    margin: 16px auto 5px auto;
    transform: translate(0, 10px);
    background-color: #fff;
    padding: 0.5rem 0.8rem;
    box-shadow: rgb(32 40 45 / 8%) 0px 2px 14px 0px !important;
    border: 1px solid #c8cef6;
    min-width: 200px;
    max-width: 400px;
    border-radius: '4px';
    max-height: 30vh;
    overflow-y: auto;

}

/* .popover__content:before { */
    /* position: absolute;
    z-index: -1;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform; */
/* } */

.popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -20px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover__message {
    text-align: center;
}