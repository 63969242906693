.listItem{
    width: 200px; 
    height: 100vh;
    /* background-color:#5b7c99; */
    /* background-color:#3872a0; */
    background-color:#383838;

}

.poll-list-filter .Mui-selected {
    background-color: #2a83ade6 !important;
    color: white !important;
}

.poll-list-filter .MuiToggleButton-sizeSmall {
    padding: 3px !important
}

/* .sidebar{ */
    /* height: auto; */
    /* padding: 0;
    width: 247px;
    height: 60px;
    border: 1px solid rgb(58, 57, 57);
    display: flex;
    color: aliceblue;
    flex-direction: row;
    justify-content: center; */
    /* font-size: large; */
    /* align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
    /* display: flex; */
    /* flex-direction: column; */
    /* border: transparent solid 1px; */
    /* padding: 16px 23px; */
    /* transition: all .3s; */
    
    
   
/* } */
 /* .sidebar:hover{
    background: #75ad83;
    color: rgb(252, 248, 248);
    cursor: pointer; */
    /* box-shadow: 0 0 5px #82bbe9,
                0 0 25px #a1d0f7,
                0 0 50px #abd5f7,
                0 0 200px #9ec7e9; */
/* } */




