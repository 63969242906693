.wrapper {
    display: flex;
    z-index: 10;
    width: 100%;
}

.wrapper .search_box {
    width: 100%;
    background: #fff;
    /* height: 65px; */
    display: flex;
    padding: 0px;
    border-radius: 30px;
    border: 1px solid #eaebee;
    box-shadow: 0 8px 6px -10px #b3c6ff;
}

.wrapper .search_box .dropdown {
    min-width: 184px;
    border-right: 1px solid #dde2f1;
    color: #3e3e3f;
    position: relative;
    cursor: pointer;
}

.wrapper .search_box .dropdown .default_option {
    text-transform: capitalize;
    padding: 13px 18px;
    /* font-size: 14px; */
}

.wrapper .search_box .dropdown ul {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    min-width: 120px;
    padding: 0px;
    display: block;
    list-style-type: none;
    margin-top: 0px;
    border: 1px solid #cececf;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.wrapper .search_box .dropdown ul.active {
    display: block;
}

.wrapper .search_box .dropdown ul li {
    padding: 13px 15px 13px 20px;
    /* text-align: center; */
    border-bottom: 1px solid #ecedef;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.wrapper .search_box .dropdown ul li:last-child {
    /* padding-bottom: 0; */
    border-bottom: none;
}

.wrapper .search_box .dropdown ul li:hover {
    color: #3e3e3f;
    background: #cdf0f4;
}

.wrapper .search_box .dropdown:before {
    content: "";
    position: absolute;
    top: 18px;
    right: 20px;
    border: 8px solid;
    border-color: #5078ef transparent transparent transparent;
}

.wrapper .search_box .search_field {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 2px;
}

.wrapper .search_box .search_field .input {
    width: 90% !important;
    margin-left: 5px;
    height: 100%;
    border: 0px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 38px;
    color: #6f768d;
}

.wrapper .search_box .search_field .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    color: #5078ef;
    cursor: pointer;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #9fa3b1;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #9fa3b1;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #9fa3b1;
}

.client-avatar {
    background: #cdf0f4 !important;
    color: #027583 !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 1rem !important;
    margin-right: 8px;
    box-shadow: 0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important
}

.clear-button {
    text-transform: capitalize !important;
    box-shadow: 0px 1px 10px -1px rgba(237, 242, 250, 0.865), 0 0 0 -8px rgba(45, 109, 204, 0.529) !important;
    /* filter: blur(14px) brightness(0.9); */
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
    /* background: #39C2C9; */
    border-radius: 20px !important;
    padding: 3px 2px !important;
    /* color: #ffffff !important; */
    /* background: linear-gradient(180deg, #0965c2 0%, #1976d2 80%, #237bd4 100%); */
}

.clear-button:hover {
    filter: brightness(0.9) contrast(1.2);
    transform: scale(0.96);
}