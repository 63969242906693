.not-voted {
    color: red;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 10px;
    font-weight: bold;
}

.voted {
    color: green;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 10px;
    font-weight: bold;
}