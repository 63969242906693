.poll-list {
    padding: 6px;
}

.blink {
    animation: blinkMe 10s linear infinite;
    cursor: pointer;
}

@keyframes blinkMe {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 3;
    }

    100% {
        opacity: 1;
    }
}