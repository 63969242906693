.hand {
    /* transform: translateY(60%); */
    /* transform: rotate3d(); */
    height: 35px;
    animation: top-to-bottom 1s ease-in forwards infinite;
}

@keyframes top-to-bottom {
    0% {
        transform: translateX(0%);
    }

    33% {
        transform: translateY(50%);
    }

}

.blink {
    animation: blinker 1.5s linear infinite;
    color: rgb(247, 242, 241);
    font-family: sans-serif;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.close:hover {
    animation: ro 2s linear infinite;
}

@keyframes ro {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.scroll {
    animation: scrolling 15s linear infinite;
}

@keyframes scrolling {
    0% {
        transform: translateX(100%);
    }

    100% {
        /* transform: translateX(-100%); */
    }
}

.round {
    border: 2px solid rgb(19, 17, 17);
    border-radius: 50%;
    padding: 8px 16px;
    width: fit-content;
    text-align: center;
    font-weight: 600;
    /* padding-left: 10px;
    padding-right: 10px; */

}

.info {
    /* width: 55%; */
    background-color: rgb(253, 238, 212);
    padding: 10px;
    border-radius: 10px;
    color: rgb(224, 74, 28);
    /* box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3); */
}

.org {
    /* width: 55%; */
    background-color: rgb(142, 202, 236);
    padding: 10px;
    border-radius: 10px;
    color: rgb(12, 29, 105);
    /* box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3); */
}

.vote-options :hover {
    background-color: red;
    background: blue;
}

.stepper {
    border: 1px solid gray;
    border-radius: 20px;
}

.texts {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    margin-top: 30px;
}

.text-not-selected {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
}

.navigation {
    color: white;
    background: #347d91;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    font-size: 10px;
    align-items: center;
    text-align: center;
}

@media (min-width: 780px) {
    .navigation {
        height: 70px;
        /* width: 100px; */
        background: #347d91;
        padding: 0px 10px;
        position: relative;
        color: white;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        margin-top: 3%;
    }

    .matter {
        font-size: 20px;
        margin-right: 57%;
    }
}

.matter {
    font-size: 20px;
}

.vote-button {
    display: none;
}


@media screen and (min-width: 401px) and (max-width: 1024px) {
    .button {
        display: block;
    }

    .vote-button {
        display: block;
    }

    .texts {
        margin-top: 0px;
        font-size: 15px;
    }
}


.icon {
    display: block;
    line-height: 71px;
    text-align: center;
    color: white;
}

.stack {
    padding: 35px;
}

/* .texts {
    transform: translateY(18px);
} */

.indicator {
    position: absolute;
    top: -50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 6px solid white;

}

.red-instruct {
    background: red;
}

.orange-matter {
    background: orange;
}

.green-review {
    background: green;
}

/* .indicators {
    position: absolute;
    top: -50%;
    width: 60px;
    height: 60px;
    background: orange;
    border-radius: 50%;
    border: 6px solid white;

}

.indicatorss {
    position: absolute;
    top: -50%;
    width: 60px;
    height: 60px;
    background: green;
    border-radius: 50%;
    border: 6px solid white;

} */

.indicator::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -22px;
    width: 20px;
    height: 50px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

}

.indicator::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

}

/* .indicators::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -22px;
    width: 20px;
    height: 50px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

}

.indicators::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

}

.indicatorss::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -22px;
    width: 20px;
    height: 50px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

}

.indicatorss::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -10px 0 0 white;

} */

.title {
    border: 0.1px solid black;
    width: 90%;
}

.btn-design {
    /* color: #255784;
    background: #255784;
    box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3; */
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3);
    color: 'white';
    background: '#2196f3';

}

@keyframes glowing {
    0% {
        background-color: #e5ebe3;
        box-shadow: 0 0 5px blue;
        color: blue;
        border: 1px solid blue;
    }

    50% {
        background-color: #ecf0eb;
        box-shadow: 0 0 20px blue;
        color: blue;
        border: 1px solid blue;
    }

    100% {
        background-color: #deecd9;
        box-shadow: 0 0 5px blue;
        color: blue;
        border: 1px solid blue;
    }
}

.buttons {
    position: relative;
    padding: 5px 40px !important;
    /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.4); */
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 4px;
    background-color: #174a7b !important;
    font: 700 30px consolas;
    overflow: hidden;
}

.buttons span span:nth-child(1) {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #0482f8, #9de6ff);
    animation: animate1 2s linear infinite;
}

@keyframes animate1 {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.buttons span span:nth-child(2) {
    position: absolute;
    top: 0;

    right: 0;

    height: 100%;

    width: 3px;

    background: linear-gradient(to bottom, #0482f8, #9de6ff);

    animation: animate2 2s linear infinite;

    animation-delay: 1s;

}

@keyframes animate2 {

    0% {

        transform: translateY(-100%);

    }



    100% {

        transform: translateY(100%);

    }

}



.buttons span span:nth-child(3) {

    position: absolute;

    bottom: 0;

    right: 0;

    width: 100%;

    height: 3px;

    background: linear-gradient(to left, #0482f8, #9de6ff);

    animation: animate3 2s linear infinite;

}



@keyframes animate3 {

    0% {

        transform: translateX(100%);

    }



    100% {

        transform: translateX(-100%);

    }



}



.buttons span span:nth-child(4) {

    position: absolute;

    top: 0;

    left: 0;

    height: 100%;

    width: 3px;

    background: linear-gradient(to top, #0482f8, #9de6ff);

    animation: animate4 2s linear infinite;

    animation-delay: 1s;

}



@keyframes animate4 {

    0% {

        transform: translateY(100%);

    }



    100% {

        transform: translateY(-100%);

    }

}