.MuiCardContent-root:last-child {
    padding-bottom: 0.5rem !important;
}

.MuiFormControlLabel-root {
    display: block !important;
}

.poll-round-action {
    float: right;
}