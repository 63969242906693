body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(248, 246, 246);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body>div {
  /* height: 100%; */
  /* background-image: linear-gradient(45deg, #090929, #b5cbe9, #6db8c3); */
  /* background-color: rgb(248, 246, 246); */
  /* background-image: linear-gradient(45deg, #000c40, #f0f2f0); */
  /* background-image: linear-gradient(45deg, #2c3e50, #bdc3c7);  */

  /* background-image: linear-gradient(50deg, #f4fbff, #717f93, #2a83ad); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.nav_icons {
  margin-left: 15px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.loading-container {
  position: relative;
}

.error-message {
  color: red;
  font-style: oblique;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.vertical-question-tab .MuiTab-root {
  min-width: 80px;
}

.icon-btn-text {
  font-size: 15px !important;
  font-weight: bold;
}

.result-btn {
  /* border-radius: 50% !important; */
  color: green !important;
  /* border-color: darkgreen !important; */
}

.round-status-new {
  border: #fdfd5ce3 solid 2px;
  background-color: #ffffe5;
  padding: 8px;
  width: 0px;
  float: right
}

.round-status-open {
  border: #b6d852 solid 2px;
  background-color: #e7f3c7;
  padding: 8px;
  width: 0px;
  float: right
}

.round-status-closed {
  border: #ff6262 solid 2px;
  background-color: #ffd8d8;
  padding: 8px;
  width: 0px;
  float: right
}


.selected-option {
  /* background-color: #50b267 !important; */
  /* border: 3px solid #50b267 !important; */
  font-weight: bolder;
  color: #0e3de9;
}

.review-title {
  color: #ce542d;
  font-weight: bold;
}

.polls-not-found {
  color: #282a2f;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  text-align: center;
  /* padding-top: 10%; */
}

.round-status-scheduled {
  border: #ea905d solid 2px;
  background-color: #fbe0d1;
  padding: 8px;
  width: 0px;
  float: right
}

.round-status-canceled {
  border: rgb(16 16 16 / 54%) solid 2px;
  background-color: rgb(187 180 180 / 31%);
  padding: 8px;
  width: 0px;
  float: right
}

.primary {
  color: #2a83ad;
}

.secondary {
  color: #ce542d;
}

.result-cell {
  padding: 8px !important;
}

.MuiButton-label {
  font-weight: bold;
}

.welcome-logo-font {
  font-size: 34px;
  font-family: "Gill Sans", sans-serif;
  font-style: italic;
  color: #2a83ad;
}

.nav-bar-logo-font {
  font-size: 24px;
  font-family: "Gill Sans", sans-serif;
  font-style: italic;
}

.grecaptcha-badge { 
    visibility: hidden;
}

/* .react-pdf__Page__textContent {
    display: none !important;
}

.react-pdf__Page__annotations {
    display: none !important;
} */

.react-pdf__Page {
    display: inline-block !important;
    width: 100% !important;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}